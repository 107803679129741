jQuery(function() {
    //FastClick.attach(document.body);
});

$(document).on('ready', function(){
  $('#revolutionSlider').revolution({
    delay:9000,
    startwidth:1170,
    startheight:500,
    hideThumbs:10
  });

  $('.validation-summary').addClass('alert alert-danger');
});